import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Alarm, CaretDown, PencilSimple, XCircle } from "phosphor-react";
import RequiredLabel from "../CustomCommons/RequiredLabel";
import PhoneInput from "react-phone-input-2";
import "./invoice.css";

import Select from "react-select";
import DoIcon from "../CustomCommons/DoIcon";

import { handleInputs } from "../../utils/HandleInputs";
import { showToast } from "../../utils/ToastHelper";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import { useEffect } from "react";
import swal from "sweetalert";
import { useRef } from "react";
import PreviewCreate from "./PreviewCreate";
import * as path from "./../Routes/RoutePaths";
import { useHistory } from "react-router-dom";
import Loader from "../CustomCommons/Loader";
import { can_add_invoice, has_permissions } from "../CustomCommons/utils";
import Permission from "../CustomCommons/Permission";
import { logout_func2 } from "../Const/logoutCommon";
import Scanner2 from "./Scanner2";

import divisions from "../../assets/json/bd_divisions.json";
import { Controller, useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";

const CreateExchange = () => {
  const userProfile = JSON.parse(window.localStorage.getItem("userData"));
  const [mixPaymentData, setMixPaymentData] = useState([]);
  const [FoundCustomer, setFoundCustomer] = useState({
    id: "",
    total_purchase: "",
    created_at: "",
    updated_at: "",
    name: "",
    mobile: "",
    email: "",
    status: "",
    address: "",
  });
  const [Found, setFound] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState({
    label: "",
    value: "",
  });
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "",
    value: "",
  });
  const [selectedTown, setSelectedTown] = useState({ label: "", value: "" });
  const [invoice, setInvoice] = useState("");
  const [invoiceProducts, setInvoiceProducts] = useState([]);
  const [selectedOldProducts, setSelectedOldProducts] = useState([]);

  useEffect(() => {
    const fetchInvoice = async (invoice) => {
      try {
        if (invoice.length !== 0) {
          const response = await axios.get(
            `${BASE_URL}/api/v1/sales/inventory/invoice-products/?invoice__number=${invoice}`
          );
          console.log(response, "respo");

          const options = response.data.data.results.map((curr) => ({
            label: curr.product_name + " " + "[[" + curr?.product_sku + "]]",
            value: curr.id,
            oldPrice: curr.total / curr.quantity,
            price: curr.total,
            discount: 0,
            // stock: curr.stock,
            quantity: curr.quantity,
            maxQuantity: curr.quantity,
            variant: curr.variant_name,
            thumb: curr.thumb,
          }));

          setInvoiceProducts(options);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        handleAxiosError(error);
      }
    };

    const handleAxiosError = (error) => {
      if (error.response) {
        console.error(
          "HTTP error:",
          error.response.status,
          error.response.data
        );
        return showToast("error", `${error.response.data.errors.error}`);
      } else if (error.request) {
        console.error("Network error:", error.request);
        return "Network error. Please check your internet connection.";
      } else {
        console.error("Error:", error.message);
        return "An error occurred. Please try again later.";
      }
    };
    fetchInvoice(invoice);
  }, [invoice]);

  console.log(invoiceProducts, selectedOldProducts, "ippp");
  // Address Form methods start
  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (Found) {
      if (FoundCustomer.redex_area) {
        // setCustomCharge(FoundCustomer?.redex_area_details.delivery_charge);
        reset({
          // division: { label: sInvoice.to_division, value: sInvoice.to_division},
          division: {
            label: FoundCustomer.redex_division_details.name,
            value: FoundCustomer.redex_division_details.id,
          },
          // district: { label: sInvoice.to_district, value: sInvoice.to_district},
          district: {
            label: FoundCustomer.redex_district_details.name,
            value: FoundCustomer.redex_district_details.id,
          },
          // town: { label: sInvoice.to_city, value: sInvoice.to_city },
          town: {
            label: FoundCustomer.redex_area_details.name,
            value: FoundCustomer.redex_area_details.id,
          },
          // zip: { label: sInvoice.to_zip_code, value: sInvoice.to_zip_code }
          zip: FoundCustomer.to_zip_code,
        });
        setSelectedDivision({
          label: FoundCustomer.redex_division_details.name,
          value: FoundCustomer.redex_division_details.id,
        });
        setSelectedDistrict({
          label: FoundCustomer.redex_district_details.name,
          value: FoundCustomer.redex_district_details.id,
        });
        setSelectedTown({
          label: FoundCustomer.redex_area_details.name,
          value: FoundCustomer.redex_area_details.id,
        });
      } else {
        reset({
          division: { label: FoundCustomer.to_division, value: "" },
          district: { label: FoundCustomer.to_district, value: "" },
          town: { label: FoundCustomer.to_city, value: "" },
          // zip: { label: sInvoice.to_zip_code, value: sInvoice.to_zip_code }
          zip: FoundCustomer.to_zip_code,
        });
        setSelectedDivision({ label: FoundCustomer.to_division, value: "" });
        setSelectedDistrict({ label: FoundCustomer.to_district, value: "" });
        setSelectedTown({ label: FoundCustomer.to_city, value: "" });
      }
    }
  }, [Found, FoundCustomer, reset]);
  const [districtList, setDistrictList] = useState([]);
  const [upazillaList, setUpazillaList] = useState([]);
  const [zipList, setZipList] = useState([]);
  const watchFields = watch([
    // "town",
    "division",
    "district",
    "town",
    "zip",
  ]);
  useEffect(() => {
    if (selectedDivision.value !== "") {
      axios
        .get(
          `${BASE_URL}/api/v1/utility/admin/redex-address/${selectedDivision.value}/`
        )
        .then((response) => {
          // setDistrictList(response.data.data.districts)
          if (response.data.data.districts !== undefined) {
            setDistrictList(response.data.data.districts);
          } else {
            setDistrictList([]);
          }
        });
    }
  }, [selectedDivision]);
  useEffect(() => {
    if (selectedDistrict.value !== "") {
      const foundAreas = districtList.filter(
        ({ id }) => id == selectedDistrict.value
      )[0]?.areas;
      // setUpazillaList(foundAreas)
      if (foundAreas !== undefined) {
        setUpazillaList(foundAreas);
      } else {
        setUpazillaList([]);
      }
    }
  }, [selectedDistrict, districtList]);
  const handleDivisionChange = (division) => {
    if (selectedDivision?.value !== undefined) {
      setSelectedDistrict({ label: "", value: "" });
      setSelectedTown({ label: "", value: "" });
      setValue("zip", "");
      setDistrictList([]);
      setSelectedDivision(division);
      setValue("division", division);
      const selectedDivisionId = division.value;
      axios
        .get(
          `${BASE_URL}/api/v1/utility/admin/redex-address/${selectedDivisionId}/`
        )
        .then((response) => {
          // setDistrictList(response.data.data.districts)
          if (response.data.data.districts !== undefined) {
            setDistrictList(response.data.data.districts);
          } else {
            setDistrictList([]);
          }
        });
    }
  };
  const handleDistrictChange = (district) => {
    if (selectedDistrict?.value !== undefined) {
      setSelectedTown({ label: "", value: "" });
      setValue("zip", "");
      setUpazillaList([]);

      setSelectedDistrict(district);
      setValue("district", district);
      const selectedDistrictId = district.value;
      const foundAreas = districtList.filter(
        ({ id }) => id == selectedDistrictId
      )[0]?.areas;
      // setUpazillaList(foundAreas)
      if (foundAreas !== undefined) {
        setUpazillaList(foundAreas);
      } else {
        setUpazillaList([]);
      }
    }
    // if (watchFields[2].label !== )
  };
  const handleTownChange = (town) => {
    if (selectedTown?.value !== undefined) {
      setValue("zip", "");
      setSelectedTown(town);
      setValue("town", town);
    }
  };
  // Address form methods end

  const [scanedCode, setScanCode] = useState("");
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const [inputQuery, setInputQuery] = useState("");
  // const [prevInv, setPrevInv] = useState({});
  const [newInputQuery, setNewInputQuery] = useState("");
  const mainEmail = "kaarujbangladesh@gmail.com";
  const mainName = "KAARUJ";
  const mainAddress = userProfile?.outlet_location
    ? userProfile?.outlet_location
    : "Road: 42, Gulshan 2, Dhaka, Bangladesh";

  const mainMobile = "+8801980907892";
  const [isLoading, SetisLoading] = useState(false);
  const [defaultValue, setdefaultValue] = useState([]);
  const [productToExchange, setProductToExchange] = useState([]);
  const [metaSelect, setMetaSelect] = useState({
    action: "select-option",
    name: "",
    option: {},
  });
  const [metaNewSelect, setMetaNewSelect] = useState({
    action: "select-option",
    name: "",
    option: {},
  });

  const print = useRef();
  const [selectedOption, setSelectedOption] = useState(null);
  const [Thumb, setThumb] = useState(null);
  const delivery_charge = {
    0: 80,
    1: 150,
  };
  const deliveryType = [
    { value: 0, label: "Regular" },
    { value: 1, label: "Urgent" },
  ];
  const invoice_choices = [
    // { value: 0, label: "Recieved" },
    // { value: 1, label: "Exchange" },
    // { value: 2, label: "Refunded" },
    { value: 3, label: "Exchange" },
  ];

  const delivery_choices = [
    // { value: 0, label: "Returned" },
    // { value: 1, label: "Order Placed" },
    { value: 2, label: "Delivered" },
    // { value: 3, label: "Pending" },
    { value: 4, label: "Hold" },
    // { value: 5, label: "Dispatched" },
  ];
  const paymentType = [
    { value: 0, label: "Cash" },
    { value: 1, label: "Card" },
    // { value: 2, label: "Bank Transfer" },
    { value: 3, label: "Bkash" },
    // { value: 4, label: "SSLECOMMERZ" },
    { value: 5, label: "Nagad" },
    // { value: 6, label: "Kaaruj Delivery" },
    { value: 7, label: "Rocket" },
    { value: 8, label: "Mixed" },
  ];
  const paymentTypeForMix = [
    { value: 0, label: "Cash", amount: 0 },
    { value: 1, label: "Card", amount: 0 },
    { value: 3, label: "Bkash", amount: 0 },
    { value: 5, label: "Nagad", amount: 0 },
    { value: 7, label: "Rocket", amount: 0 },
  ];

  const handleInputChange = (id, newValue) => {
    // Create a new array with the updated amount for the payment with the specified id.
    const updatedPayments = mixPaymentData?.map((payment) =>
      payment.value === id ? { ...payment, amount: newValue } : payment
    );

    setMixPaymentData(updatedPayments);
  };

  const [pS, setPs] = useState(0);

  const [fromCode, setFromCode] = useState("880");
  const [toCode, setToCode] = useState("880");
  const [customCharge, setCustomCharge] = useState(0);
  console.log("crg", customCharge);
  const today = new Date();

  // Format the date as "YYYY-MM-DD"
  const formattedDate = today.toISOString().split("T")[0];

  const [InvoiceAllData, SetInvoiceAllData] = useState({
    invoice_date: formattedDate,
    invoice_due_date: "",
    invoice_bill_from: mainName,
    invoice_bill_to: "",
    invoice_from_mobile: mainMobile.replace("+880", ""),
    invoice_to_mobile: "",
    invoice_from_email: mainEmail,
    invoice_to_email: "",
    invoice_from_address: mainAddress,
    invoice_to_address: "",
    invoice_to_address2: "",
    to_zip_code: "",
    to_country: "Bangladesh",
    to_district: "",
    to_division: "",
    to_city: "",
    invoice_notes: "",
    invoice_delivery_charge: 0,
    recived_amount: 0,
  });

  const [paymentAllData, setPaymentAllData] = useState({
    card_holder_name: "",
    card_number: "",
    bank_name: "",
    banK_account_number: "",
    banK_account_name: "",
    banK_branch_name: "",
    bkash_number: "",
    bkash_trx_number: "",
    nagad_number: "",
    nagad_trx_number: "",
    reference_id: "",
  });
  const clearPaymentALlData = () => {
    setPaymentAllData({
      card_holder_name: "",
      card_number: "",
      bank_name: "",
      banK_account_number: "",
      banK_account_name: "",
      banK_branch_name: "",
      bkash_number: "",
      bkash_trx_number: "",
      nagad_number: "",
      nagad_trx_number: "",
      reference_id: "",
    });
  };
  const [invoice_status, setinvoice_status] = useState(3);
  const [Delivery_status, setDelivey_status] = useState(2);
  const [invoice_delivery_type, setinvoice_delivery_type] = useState(0);
  const [invoice_discount_type, setinvoice_discount_type] = useState(0);
  const [invoice_payment_type, setinvoice_payment_type] = useState(0);
  const [invoice_delivery_charge, setinvoice_delivery_charge] = useState(2);
  const [InvoiceBelowData, SetInvoiceBelowData] = useState({
    invoice_vat: 0,
    invoice_tax: 0,
    invoice_due: 0,
    invoice_paid: 0,
    invoice_total: 0,
  });

  const [optional, setOptional] = useState("");
  const [URL, setURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [Items, SetItems] = useState([]);
  const [Signature, SetSignature] = useState(null);
  const [ProductsList, setProductsList] = useState([]);
  const [newProductsList, setNewProductsList] = useState([]);
  const [CustomerList, setCustomerList] = useState([]);
  const [ProductCom, setProductCom] = useState([]);
  const [mainState, setMainState] = useState({});
  const [newMainState, setNewMainState] = useState({});
  const [mainInnerState, setMainInnerState] = useState({});
  const [newMainInnerState, setNewMainInnerState] = useState({});
  const [Jdata, setJData] = useState([]);
  useEffect(() => {
    let temp1 = mainState;
    console.log(temp1, "--------mainState found temp1------");

    var output = [];
    for (let i in temp1) {
      console.log(temp1[i]["variantObj"]);
      for (let j in temp1[i]["variantObj"]) {
        console.log(j);
        console.log(temp1[i]["variantObj"][j]);
        const d = temp1[i]["variantObj"][j];
        d.total !== 0 &&
          output.push({
            name: i,
            basePrice: temp1[i].price,
            thumb: temp1[i].thumb ? temp1[i].thumb : temp1[i].thumb_url,
            discount: isNaN(temp1[i].discount) === true ? 0 : temp1[i].discount,
            price: d["price"],
            quantity: d["quantity"],
            id: d["id"],
            total: d["total"],
            variant: d["name"],
          });
      }
    }
    setJData(output);
  }, [mainState]);

  console.log("FoundCustomer", FoundCustomer);
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
    }),
  };

  const convertData = (date) => {
    var current = new Date(date);
    return current.toISOString().substring(0, 10);
  };

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const history = useHistory();
  const ValidateData = (sendEmail) => {
    const bd = /^[1][0-9]{9}$/;

    if (InvoiceAllData["invoice_date"] === "") {
      showToast("error", "Invoice  Date can't be empty..");
      return 0;
    } else if (!bd.test(InvoiceAllData["invoice_from_mobile"])) {
      showToast("error", "From Mobile : Please Enter Valid phone number");
      return 0;
    } else if (!bd.test(InvoiceAllData["invoice_to_mobile"])) {
      showToast("error", "To Mobile : Please Enter Valid phone number");
      return 0;
    } else if (!Found && InvoiceAllData["invoice_bill_from"] === "") {
      showToast("error", "Bill from  can't be empty..");
      return 0;
    }

    if (InvoiceAllData["invoice_from_email"] === "") {
      showToast("error", "From email  can't be empty..");
      return 0;
    } else if (!validateEmail(InvoiceAllData["invoice_from_email"])) {
      showToast("error", "From email  not valid..");
      return 0;
    } else if (InvoiceAllData["invoice_from_address"] === "") {
      showToast("error", "From address  can't be empty..");
      return 0;
    }

    if (selectedOldProducts.length <= 0) {
      showToast("error", "Product Details is empty..");
      return 0;
    }
    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

    const allvariants = Object.values(mainState).map(
      (current) => current["variantObj"]
    );

    if (
      invoice_payment_type === 8 ||
      (invoice_payment_type === "8" && mixPaymentData?.length === 0)
    ) {
      showToast("error", "Please add mix payment");
      return 0;
    }
    if (InvoiceAllData["invoice_notes"] === null) {
      showToast("error", "Notes is empty..");
      return 0;
    }
    swal({
      title: 'Transaction summary',
      text: `New subtotal: ${isNaN(getNewSubTotal()) ? 0 : getNewSubTotal()}
             Previous paid: ${getSubTotal()}
             New paid: ${paid}`,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: false,
          visible: true,
          className: 'btn btn-secondary',
          closeModal: true
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn btn-success',
          closeModal: true
        }
      }
    }).then((result) => {
      if (result) {
        console.log('Button clicked!');
        postInvoice(sendEmail);
      }
    });
  };

  console.log("invoice_payment_type", invoice_payment_type);

  function formatInv(inputData) {
    const formattedData = [];

    // Iterate over the data object
    for (const key in inputData) {
      const product = inputData[key];
      const variants = product.variant;

      // Iterate over the variants array for each product
      for (let i = 0; i < variants.length; i++) {
        const variant = variants[i];

        // Check if quantity is greater than 0
        if (variant.quantity > 0) {
          // Create a new formatted object using the variant data
          const formattedObject = {
            quantity: variant.quantity,
            total: variant.total.toString(),
            product_name: key.trim(),
            variant_name: variant.name,
            is_custom: false,
            invoice_date: InvoiceAllData["invoice_date"],
            product: product.id,
            variant: variant.id,
            is_outlet: true,
            outlet: userProfile?.outlet,
            invoice: variant.prevInv,
          };

          // Add the formatted object to the formattedData array
          formattedData.push(formattedObject);
        }
      }
    }

    return formattedData;
  }

  const SaveAndSendEmail = () => {
    ValidateData(true);
  };
  const handleClick = (category) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=212px, initial-scale=1.0">
            <title>${category.number}</title>
            <style>
              /* Add your CSS styles here */
              table, tr, td {
                border: none;
            }
            body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                width: 212px;
                font-weight: normal;
            }

            .container {
                width: 100%;
            }

            .header {
                text-align: center;
            }

            .address {
                margin: 5px 0;
                text-align: center;
            }

            .address p {
                margin: 3px;
                font-size: 10px;
            }

            .invoice-details {
                margin: 5px 0;
            }

            .invoice-details p {
                margin: 3px 0;
                font-size: 10px;
            }

            .invoice-table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 5px;
            }

            .invoice-table th,
            .invoice-table td {
                border: 1px solid #3327272b;
                padding: 3px;
                text-align: center;
                font-size: 10px;
            }

            .invoice-table th {
                background-color: #f2f2f2;
            }

            .total-section {
                margin-top: 5px;
            }
            .invoice-note ul li {
              margin: 8px 0;
              font-size: 10px;
          }

            .total-section p {
                margin: 3px 0;
                text-align: right;
                font-size: 10px;
            }
            .header {
                text-align: center;
                margin-top: 5px;
            }

            .address {
                margin: 5px 0 0 0;
                text-align: center;
            }

            @media print {
                .invoice-table {
                    page-break-inside: avoid;
                }
            }
            </style>
          </head>
          <body>
            <div class="">
              <div class="header" style="margin-bottom: 5px; font-weight:bolder;">
                <p>Bill Receipt</p>
              </div>

              <div class="address">
                <div class="address" style="margin-bottom: 0px;font-weight:bolder;">
                  <p4>${category.bill_from}</p4>
                </div>
                <p>${category.from_address}</p>
                <p>Mobile: ${category.from_mobile}</p>
      <p> Email: ${category.from_email}</p>
      <p>BIN No: 004187577-0201</p>
              <p>VAT: Mushak 6.5</p>
                <p>Date: ${category.invoice_date}</p>
                <p>Invoice Type: ${category.is_exchanged ? "Exchange" : category.is_returned ? "Refund" : "Regular"}</p>
              </div>

              <div class="invoice-details">
                <p>Invoice No: ${category.number}</p>
                <p>Name: ${category.bill_to}</p>
                <p>Address: ${
                  category.to_address ? category.to_address : "Dhaka"
                }</p>
                <p>Phone: ${category.to_mobile} </p>
                ${category.to_email && `<p>Email: ${category.to_email}</p>`}

              </div>

              <table class="invoice-table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  ${category.invoice_products
                    .map(
                      (item) => `
                        <tr>
                          <td><p>${item.product_name}(${
                        item.variant_name
                      })</p></td>
                          <td><p>${
                            Number(item.total) / Number(item?.quantity)
                          }</p></td>
                          <td><p>${item.quantity}</p></td>
                          <td><p>${item.total}</p></td>
                        </tr>
                      `
                    )
                    .join("")}
                </tbody>
              </table>

              <div class="total-section">
                <p>Total Item: ${category.invoice_products.length}</p>
                <p>Total Amount: ${category.total_amount}</p>

                <p>Discount: ${category?.discount_type === 0 ? "৳" : ""}
                ${category.total_discount}
                ${category?.discount_type === 1 ? "%" : ""} </p>
                <p>Paid Amount: ${category.total_paid}</p>
                <p>Due Amount: ${category.total_due}</p>
              </div>
            </div>

            <div class='invoice-note'>
            <ul>
            <li>Product is non-refundable if there is no major manufacturing fault. Refund process will be completed within 10 business days after receiving faulty products, checked and accepted by the seller.</li>
            <li>Exchange only allowed within 7 days with the same or higher price product.</li>
            <li>Product will be delivered within Bangladesh upon receipt of full payment.</li>
          </ul>
      </div>
          </body>
        </html>
      `);

    printWindow.document.close();
    printWindow.print();
    history.push(path.invoice_list);
  };
  const postInvoice = (sendEmail) => {
    const url = `${BASE_URL}api/v1/sales/inventory/invoice/`;
    const data = new FormData();

    const current_user = JSON.parse(localStorage.getItem("userData"));
    data.append(
      "invoice_date",
      InvoiceAllData["invoice_date"] !== "" &&
        convertData(InvoiceAllData["invoice_date"])
    );

    data.append("bill_from", InvoiceAllData["invoice_bill_from"]);
    data.append(
      "bill_to",
      Found ? FoundCustomer.name : InvoiceAllData["invoice_bill_to"]
    );
    data.append("from_email", InvoiceAllData["invoice_from_email"]);
    data.append(
      "to_email",
      Found ? FoundCustomer.email : InvoiceAllData["invoice_to_email"]
    );
    data.append(
      "from_mobile",
      `+${fromCode}${InvoiceAllData["invoice_from_mobile"]}`
    );
    data.append(
      "to_mobile",
      `+${toCode}${InvoiceAllData["invoice_to_mobile"]}`
    );
    data.append("from_address", InvoiceAllData["invoice_from_address"]);
    data.append(
      "to_address",
      Found ? FoundCustomer.address : InvoiceAllData["invoice_to_address"]
    );
    data.append(
      "delivery_charge",
      parseInt(invoice_delivery_charge) === 2
        ? customCharge
        : delivery_charge[InvoiceAllData["invoice_delivery_charge"]]
    );
    data.append("delivery_charge_type", parseInt(invoice_delivery_charge));

    data.append("total_discount", Discount);
    data.append("send_pdf", sendEmail);

    data.append("discount_type", invoice_discount_type);
    data.append(
      "product_list_json",
      JSON.stringify({
        mainstate: mainState,
        maininnerstate: mainInnerState,
      })
    );

    data.append("invoice_view_json", JSON.stringify(Jdata));

    data.append("payment_status", invoice_status.value);
    data.append("delivery_status", Delivery_status.value);
    data.append("delivery_type", invoice_delivery_type);
    data.append("payment_type", invoice_payment_type);
    data.append("payment_type", invoice_payment_type);
    data.append("card_holder_name", paymentAllData["card_holder_name"]);
    data.append("card_number", paymentAllData["card_number"]);
    data.append("card_expiry_date", paymentAllData["card_expiry_date"]);
    data.append("bank_name", paymentAllData["bank_name"]);
    data.append("banK_account_number", paymentAllData["banK_account_number"]);
    data.append("banK_routing_number", paymentAllData["banK_routing_number"]);
    data.append("banK_account_name", paymentAllData["banK_account_name"]);
    data.append("banK_branch_name", paymentAllData["banK_branch_name"]);
    data.append("bkash_number", paymentAllData["bkash_number"]);
    data.append("bkash_trx_number", paymentAllData["bkash_trx_number"]);
    // data.append("payment_status", 0);
    data.append("signature", Signature);
    data.append("notes", InvoiceAllData["invoice_notes"]);
    data.append("created_by", current_user.id);
    data.append("total_tax", 0);
    data.append("total_due", parseInt(getDueAmount()));
    data.append("total_paid", paid);
    data.append("total_amount", parseInt(getGrandTotal()));
    data.append("total_vat", 0);
    data.append("invoice_products", JSON.stringify(formatInv(mainState)));

    SetisLoading(true);
    const data2 = {
      invoice_date:
        InvoiceAllData["invoice_date"] !== "" &&
        convertData(InvoiceAllData["invoice_date"]),
      bill_from: InvoiceAllData["invoice_bill_from"],
      bill_to: Found
        ? FoundCustomer.name
        : InvoiceAllData["invoice_bill_to"]
        ? InvoiceAllData["invoice_bill_to"]
        : "",
      from_email: InvoiceAllData["invoice_from_email"],
      to_email: Found
        ? FoundCustomer.email
        : InvoiceAllData["invoice_to_email"],
      from_mobile: `+${fromCode}${InvoiceAllData["invoice_from_mobile"]}`,
      to_mobile: `+${toCode}${InvoiceAllData["invoice_to_mobile"]}`,
      from_address: InvoiceAllData["invoice_from_address"],
      to_address: Found
        ? FoundCustomer.to_address
        : InvoiceAllData["invoice_to_address"]
        ? InvoiceAllData["invoice_to_address"]
        : "",
      delivery_charge:
        parseInt(invoice_delivery_charge) === 2
          ? customCharge
          : delivery_charge[InvoiceAllData["invoice_delivery_charge"]],
      delivery_charge_type: parseInt(invoice_delivery_charge),
      total_discount: Discount,
      send_pdf: sendEmail,
      discount_type: invoice_discount_type,
      product_list_json: JSON.stringify({
        mainstate: mainState,
        maininnerstate: mainInnerState,
      }),
      invoice_view_json: JSON.stringify(Jdata),
      payment_status: invoice_status,
      delivery_status: Delivery_status,
      delivery_type: invoice_delivery_type,
      payment_type: invoice_payment_type,
      card_holder_name: paymentAllData["card_holder_name"],
      card_number: paymentAllData["card_number"],
      card_expiry_date: paymentAllData["card_expiry_date"],
      bank_name: paymentAllData["bank_name"],
      banK_account_number: paymentAllData["banK_account_number"],
      banK_routing_number: paymentAllData["banK_routing_number"],
      banK_account_name: paymentAllData["banK_account_name"],
      banK_branch_name: paymentAllData["banK_branch_name"],
      bkash_number: paymentAllData["bkash_number"],
      bkash_trx_number: paymentAllData["bkash_trx_number"],
      nagad_number: paymentAllData["nagad_number"],
      nagad_trx_number: paymentAllData["nagad_trx_number"],
      reference_id: paymentAllData["reference_id"],
      signature: Signature,
      notes: InvoiceAllData["invoice_notes"],
      notes2: InvoiceAllData["invoice_notes2"],
      created_by: current_user.id,
      total_tax: 0,
      total_due: parseInt(getNewDueAmount()) - paid,
      total_paid: paid,
      // total_amount: Math.abs(parseInt(getDiscountedNewSubTotal())),
      total_amount: paid,
      previous_invoice_number: invoice,
      previous_paid: getSubTotal(),
      non_refundable_amount: getNewGrandTotal() < 0 ? Math.abs(getNewGrandTotal()) : 0,
      total_cash_recieved: InvoiceAllData["recived_amount"],
      change_amount: Math.abs(
        isNaN(getNewDueAmount())
          ? 0
          : getNewDueAmount() - InvoiceAllData["recived_amount"]
      ),
      sub_total: isNaN(getNewSubTotal()) ? 0 : getNewSubTotal(),
      total_vat: 0,
      invoice_products: formatInv(newMainState).map((obj) => {
        const { invoice, ...rest } = obj;
        return rest;
      }),
      to_address2: Found
        ? FoundCustomer.to_address2
        : InvoiceAllData["invoice_to_address2"]
        ? InvoiceAllData["invoice_to_address2"]
        : "",
      to_country: Found
        ? FoundCustomer.to_country
        : InvoiceAllData["to_country"],
      to_district: Found
        ? FoundCustomer.to_district
        : watchFields[1]?.label
        ? watchFields[1]?.label
        : "",
      redex_division: watchFields[0]?.value,
      redex_district: watchFields[1]?.value,
      redex_area: watchFields[2]?.value,
      to_division: Found
        ? FoundCustomer.to_division
        : watchFields[0]?.label
        ? watchFields[0]?.label
        : "",
      to_zip_code: Found ? FoundCustomer.to_zip_code : watchFields[3],
      to_city: Found ? FoundCustomer.to_city : watchFields[2]?.label,
      is_outlet: true,
      outlet: userProfile?.outlet,
      mixed_payment_details: mixPaymentData,
      // exchange_products_json: {
      //   data: formatInv(mainState).map((item, index) => {
      //     return {
      // product: item.product,
      // variant: item.variant,
      //       quantity: item.quantity,
      //       invoice: item.invoice,
      //       previous_product: item.product,
      //       previous_variant: item.variant,
      //     };
      //   }),
      // },
      exchange_products_json: {
        data: selectedOldProducts.map((item, index) => {
          return {
            // product: item.product,
            // variant: item.variant,
            quantity: item.quantity,
            inv_product: item.value,
            // invoice: item.invoice,
            // previous_product: item.product,
            // previous_variant: item.variant,
          };
        }),
      },
      is_exchanged: true,
    };

    axios
      .post(url, data2)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data.data);
          SetisLoading(false);

          showToast("success", "Invoice Created.");
          history.push(path.invoice_list);
          handleClick(res.data.data);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        console.log(message, "emm");
        showToast("error", message);
        SetisLoading(false);
      });
  };
  const getProductList2 = () => {
    console.log(scanedCode, "code");
    let url;
    if (scanedCode) {
      url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet/?query=${scanedCode}`;
    }

    axios
      .get(url)
      .then((res) => {
        // console.log("productlistinvoice", res.data);
        const result = res.data.data;

        const options = result.map((curr) => ({
          label: curr.name + " " + "[[" + curr?.sku + "]]",
          value: curr.id,
          price: curr.price,
          discount: curr.discount,
          stock: curr.stock,
          variant: curr.variants,
          thumb: `${curr.thumb}`,
        }));

        setProductsList(options);
        // setLoading(false);
        setScanCode("");
        if (options.length > 0) {
          let variants = options[0]?.variant.map((curr) => ({
            id: curr.id,
            name: curr.name,
            price: curr.price,
            stock: curr.stock,
            product: curr.product,
            quantity: -1,
            total: 0,
          }));
          const variantsObj = {};
          options[0]?.variant.map(
            (curr) =>
              (variantsObj[curr.name] = {
                id: curr.id,
                name: curr.name,
                price: curr.price,
                stock: curr.stock,
                product: curr.product,
                quantity: -1,
                total: 0,
              })
          );

          setMetaSelect({
            action: "select-option",
            name: undefined,

            option: {
              id: options[0]?.value,
              variant: variants,
              label: options[0]?.label,
              variantObj: variantsObj,
              price: parseInt(options[0]?.price),
              stock: parseInt(options[0]?.stock),
              thumb: options[0]?.thumb,
              discount: parseInt(options[0]?.discount),
            },
          });
          setMetaNewSelect({
            action: "select-option",
            name: undefined,

            option: {
              id: options[0]?.value,
              variant: variants,
              label: options[0]?.label,
              variantObj: variantsObj,
              price: parseInt(options[0]?.price),
              stock: parseInt(options[0]?.stock),
              thumb: options[0]?.thumb,
              discount: parseInt(options[0]?.discount),
            },
          });
          console.log("metaSlect2", metaSelect);
          setSelectedOption({});
          // handleMultiInputChange(options[0], metaSelect);
        }
      })
      .catch((err) => {
        // const message = JSON.parse(err.request.response).message;
        // console.log(err.request.response);
      });
  };
  useEffect(() => {
    if (metaSelect?.option?.variant) {
      const newValue = {
        value: ProductsList[0].value,
        label: ProductsList[0].label,
      };
      // Check if the new value already exists in `defaultValue`
      const isValueExists = defaultValue.some(
        (item) => item.value === newValue.value
      );
      if (!isValueExists) {
        handleMultiInputChange(ProductsList[0], metaSelect);
        setdefaultValue([...defaultValue, newValue]);
      }
    }
  }, [metaSelect]);

  useEffect(() => {
    if (metaNewSelect?.option?.variant) {
      const newValue = {
        value: newProductsList[0].value,
        label: newProductsList[0].label,
      };
      // Check if the new value already exists in `defaultValue`
      const isValueExists = defaultValue.some(
        (item) => item.value === newValue.value
      );
      if (!isValueExists) {
        handleNewMultiInputChange(newProductsList[0], metaNewSelect);
        setProductToExchange([...productToExchange, newValue]);
      }
    }
  }, [metaNewSelect]);

  const getProductList = () => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet`;
      } else if (inputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet/?query=${inputQuery}`;
      }

      axios
        .get(url)
        .then((res) => {
          // console.log("productlistinvoice", res.data);
          const result = res.data.data;
          console.log(result, "------productlistinvoice-----");
          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            price: curr.price,
            discount: curr.discount,
            stock: curr.stock,
            variant: curr.variants,
            thumb: `${curr.thumb_url}`,
          }));

          setProductsList(options);
          // SetisLoading(false);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  const getNewProductList = () => {
    if (newInputQuery?.length > 1) {
      let url;
      if (
        !newInputQuery ||
        newInputQuery === "" ||
        newInputQuery === undefined
      ) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet`;
      } else if (newInputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/product_list_for_invoice/${userProfile?.outlet}/outlet/?query=${newInputQuery}`;
      }

      axios
        .get(url)
        .then((res) => {
          // console.log("productlistinvoice", res.data);
          const result = res.data.data;
          console.log(result, "------productlistinvoice-----");
          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            price: curr.price,
            discount: curr.discount,
            stock: curr.stock,
            variant: curr.variants,
            thumb: `${curr.thumb_url}`,
          }));

          setNewProductsList(options);
          // SetisLoading(false);
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };
  // console.log("ProductsList", ProductsList);

  // const getCustomerList = () => {
  //   const url = `${BASE_URL}api/v1/inventory/inventory/customer_list_for_invoice/`;

  //   axios
  //     .get(url)
  //     .then((res) => {
  //       // console.log('res',res.data.data)
  //       const result = res.data.data;
  //       setCustomerList(result);
  //       // SetisLoading(false);
  //     })
  //     .catch((err) => {
  //       const message = JSON.parse(err.request.response).message;
  //       if (
  //         message === "Invalid token." ||
  //         JSON.parse(err.request.response).code === 401
  //       ) {
  //         // history.push('/login')
  //         logout_func2(setOnlogoutHide, history);
  //       }
  //     });
  // };

  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
    console.log("searched", inputQuery);
  };
  const newInputProduct = (inputValue) => {
    setNewInputQuery(inputValue);
    console.log("searched", newInputQuery);
  };
  const handleMultiInputChange = (newValue, actionMeta) => {
    // const newVal = actionMeta?.option?.label?.split("[[")[0];
    const newVal = actionMeta?.option?.label;
    setProductCom(newValue);
    console.log("newValue 123", newValue);
    console.log("newValue 1234", actionMeta?.option?.label);
    if (actionMeta.action === "select-option" && actionMeta?.option?.variant) {
      let variants = actionMeta?.option?.variant.map((curr) => ({
        id: curr.id,
        name: curr.name,
        price: curr.price,
        stock: curr.stock,
        product: curr.product,
        quantity: -1,
        total: 0,
      }));
      const variantsObj = {};
      actionMeta.option?.variant.map(
        (curr) =>
          (variantsObj[curr.name] = {
            id: curr.id,
            name: curr.name,
            price: curr.price,
            stock: curr.stock,
            product: curr.product,
            quantity: -1,
            total: 0,
          })
      );

      // setMainState({
      //   ...mainState,
      //   [newVal]: {
      //     id: actionMeta.option.value,
      //     variant: variants,
      //     variantObj: variantsObj,
      //     price: parseInt(actionMeta.option.price),
      //     stock: parseInt(actionMeta.option.stock),
      //     thumb: actionMeta.option.thumb,
      //     discount: parseInt(actionMeta.option.discount),
      //   },
      // });
      setMainState((prevMainState) => ({
        [newVal]: {
          id: actionMeta.option.value,
          variant: variants,
          variantObj: variantsObj,
          price: parseInt(actionMeta.option.price),
          stock: parseInt(actionMeta.option.stock),
          thumb: actionMeta.option.thumb,
          discount: parseInt(actionMeta.option.discount),
        },
        ...prevMainState,
      }));
      setdefaultValue([
        ...defaultValue,
        {
          value: actionMeta.option.value,
          label: newVal,
        },
      ]);
    }
    //remove data from select
    if (actionMeta.action === "remove-value") {
      delete mainState[actionMeta.removedValue.label];
      setMainState({ ...mainState });
      setdefaultValue((prevDefaultValue) =>
        prevDefaultValue.filter(
          (value) =>
            value.label !== actionMeta.removedValue.label &&
            value.value !== actionMeta.removedValue.value
        )
      );
      setScanCode("");
    }
  };
  const handleNewMultiInputChange = (newValue, actionMeta) => {
    // const newVal = actionMeta?.option?.label?.split("[[")[0];
    const newVal = actionMeta?.option?.label;
    setProductCom(newValue);
    console.log("newValue-123", actionMeta, newValue);
    console.log("newValue-1234", actionMeta?.option?.label);
    if (actionMeta.action === "select-option" && actionMeta?.option?.variant) {
      let variants = actionMeta?.option?.variant.map((curr) => ({
        id: curr.id,
        name: curr.name,
        price: curr.price,
        stock: curr.stock,
        product: curr.product,
        quantity: -1,
        in_discount: 0,
        total: 0,
      }));
      const variantsObj = {};
      actionMeta.option?.variant.map(
        (curr) =>
          (variantsObj[curr.name] = {
            id: curr.id,
            name: curr.name,
            price: curr.price,
            stock: curr.stock,
            product: curr.product,
            quantity: -1,
            in_discount: 0,
            total: 0,
          })
      );

      setNewMainState({
        ...newMainState,
        [newVal]: {
          id: actionMeta.option.value,
          variant: variants,
          variantObj: variantsObj,
          price: parseInt(actionMeta.option.price),
          stock: parseInt(actionMeta.option.stock),
          thumb: actionMeta.option.thumb,
          discount: parseInt(actionMeta.option.discount),
          in_discount: 0,
        },
      });
      setProductToExchange([
        ...productToExchange,
        {
          value: actionMeta.option.value,
          label: newVal,
        },
      ]);
    }
    //remove data from select
    if (actionMeta.action === "remove-value") {
      delete newMainState[actionMeta.removedValue.label];
      setNewMainState({ ...newMainState });
      setProductToExchange((prevDefaultValue) =>
        prevDefaultValue.filter(
          (value) =>
            value.label !== actionMeta.removedValue.label &&
            value.value !== actionMeta.removedValue.value
        )
      );
      setScanCode("");
    }
  };

  const handleInnerVariant = (currentName, value) => {
    // console.log("currentName", currentName);
    // console.log("value", value);
    setMainInnerState({
      ...mainInnerState,
      [currentName]: value,
    });
  };
  const handleNewInnerVariant = (currentName, value) => {
    // console.log("currentName", currentName);
    // console.log("value", value);
    setNewMainInnerState({
      ...newMainInnerState,
      [currentName]: value,
    });
  };

  // console.log("mainInnerState", mainInnerState);

  useEffect(() => {
    getProductList2();
    // setTimeout(() => {
  }, [scanedCode]);
  useEffect(() => {
    getProductList();
  }, [inputQuery]);
  useEffect(() => {
    getNewProductList();
  }, [newInputQuery]);

  // useEffect(() => {
  //   getCustomerList();
  // }, []);
  console.log("mainState", newMainState, productToExchange, mainState);

  useEffect(() => {
    Object.keys(ProductsList).map((curr, index) => {
      setMainState({
        ...mainState,
        [curr]: { ...mainState[curr], in_price: ProductsList[index]["price"] },
      });
    });
  }, []);
  useEffect(() => {
    Object.keys(newProductsList).map((curr, index) => {
      setNewMainState({
        ...newMainState,
        [curr]: {
          ...newMainState[curr],
          in_price: newProductsList[index]["price"],
        },
      });
    });
  }, []);

  // console.log("CustomerList", CustomerList);
  const toPhoneHandle = (e) => {
    let value = `+${toCode}${e.target.value}`;
    let number = e.target.value;
    handleInputs(e, SetInvoiceAllData);
    if (number.length === 10) {
      const url = `${BASE_URL}/api/v1/inventory/inventory/customer/?limit=20&offset=0&filter=&search=${number}
      `;
      axios.get(url).then((res) => {
        if (res.data.data.results.length !== 0) {
          setFound(true);
          setFoundCustomer(res.data.data.results[0]);
          showToast("success", "Found Customer");
        } else {
          setFound(false);
        }
      });
    }
  };

  const VariantThead = () => {
    return (
      <>
        {Object.keys(mainInnerState).length > 0 && (
          <thead style={{ border: "1.5px solid #E0E0E0" }}>
            <tr className="px-3" style={{ height: "45px" }}>
              <th
                className="minWidthForInvoiceCreateTable ps-4"
                style={{ width: "349px", textAlign: "start" }}
              >
                Variant
              </th>
              <th
                className="minWidthForInvoiceCreateTable ps-4"
                style={{ width: "349px", textAlign: "start" }}
              >
                Previous Invoice
              </th>
              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Price
              </th>
              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Discount{" "}
                {invoice_discount_type === "0" || invoice_discount_type === 0
                  ? "(Tk)"
                  : "(%)"}{" "}
              </th>

              {/* <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                In Stock
              </th> */}
              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Quantity
              </th>

              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Total
              </th>
            </tr>
          </thead>
        )}
      </>
    );
  };
  const NewVariantThead = () => {
    return (
      <>
        {Object.keys(newMainInnerState).length > 0 && (
          <thead style={{ border: "1.5px solid #E0E0E0" }}>
            <tr className="px-3" style={{ height: "45px" }}>
              <th
                className="minWidthForInvoiceCreateTable ps-4"
                style={{ width: "349px", textAlign: "start" }}
              >
                Variant
              </th>

              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Price
              </th>
              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Discount{" "}
                {invoice_discount_type === "0" || invoice_discount_type === 0
                  ? "(Tk)"
                  : "(%)"}{" "}
              </th>

              <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                In Stock
              </th>
              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Quantity
              </th>

              <th
                className="minWidthForInvoiceCreateTable py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                Total
              </th>
            </tr>
          </thead>
        )}
      </>
    );
  };
  const [paid, setpaid] = useState(0);
  const [previouslyPaid, setPreviouslyPaid] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const getTotal = (curr, innerCur) => {
    return (
      Object.keys(mainState).length > 0 &&
      mainState !== undefined &&
      mainState[curr] !== undefined &&
      mainState[curr]["variant"]["total"]
    );
  };

  const getAllVariantName = () => {
    var res =
      mainInnerState !== undefined &&
      [].concat(
        ...Object.values(mainInnerState).map((a) => a.map((b) => b.label))
      );
    return res;
  };
  const getAllNewVariantName = () => {
    var res =
      newMainInnerState !== undefined &&
      [].concat(
        ...Object.values(newMainInnerState).map((a) => a.map((b) => b.label))
      );
    return res;
  };
  // console.log("getAllVariantName", getAllVariantName());

  const getSubTotal = () => {
    // const allarray = Object.values(mainState).map(
    //   (curr) =>
    //     curr.variant !== undefined &&
    //     curr.variant
    //       .map((variants) =>
    //         getAllVariantName().includes(variants.name)
    //           ? parseInt(variants.total)
    //           : 0
    //       )
    //       .reduce((a, b) => a + b, 0)
    // );
    const allarray = selectedOldProducts.map(({ price, discount }) => ({
      price,
      discount,
    }));

    // var sum = 0;
    // for (let i in allarray) {
    //   sum += parseInt(allarray[i]);
    // }
    const totalPriceAfterDiscount = allarray.reduce((sum, item) => {
      // const priceAfterDiscount = parseInt(item.price) - parseInt(item.discount);
      const priceAfterDiscount = parseInt(item.price);
      return sum + priceAfterDiscount;
    }, 0);
    return totalPriceAfterDiscount;
  };
  const getNewSubTotal = () => {
    const allarray = Object.values(newMainState).map(
      (curr) =>
        curr.variant !== undefined &&
        curr.variant
          .map((variants) =>
            getAllNewVariantName().includes(variants.name)
              ? parseInt(variants.total)
              : 0
          )
          .reduce((a, b) => a + b, 0)
    );

    console.log(newMainState, allarray, "nn4");
    var sum = 0;
    for (let i in allarray) {
      sum += parseInt(allarray[i]);
    }
    return sum;
  };
  const getDiscountOrMainPrice = (curr, innerCur) => {
    return parseInt(
      mainState[curr] !== undefined &&
        (mainState[curr].discount === 0
          ? mainState[curr].price
          : mainState[curr].discount)
    );
  };
  const getNewDiscountOrMainPrice = (curr, innerCur) => {
    return parseInt(
      newMainState[curr] !== undefined &&
        (newMainState[curr].discount === 0
          ? newMainState[curr].price
          : newMainState[curr].discount)
    );
  };
  const getBasePlusVariant = (curr, innerCur) => {
    return getDiscountOrMainPrice(curr, innerCur) + parseInt(innerCur.price);
  };
  const getNewBasePlusVariant = (curr, innerCur) => {
    return getNewDiscountOrMainPrice(curr, innerCur) + parseInt(innerCur.price);
  };
  const getFlatDiscount = () => {
    return Discount;
  };

  const getDeliveryCharge = () => {
    if (invoice_delivery_charge === 2) {
      return customCharge;
    } else {
      return delivery_charge[invoice_delivery_charge];
    }
  };
  const getPercentageDiscount = () => {
    let total = getNewSubTotal();
    return parseInt((Discount / 100) * total);
  };

  const getGrandTotal = () => {
    const discountCalc =
      invoice_discount_type === 0 || invoice_discount_type === "0"
        ? getFlatDiscount()
        : getPercentageDiscount();
    const charge =
      parseInt(invoice_delivery_charge) !== 2
        ? parseInt(getDeliveryCharge())
        : parseInt(customCharge);
    const grand = getSubTotal() + charge - discountCalc;
    return grand < 0 ? 0 : parseInt(grand);
  };
  const getNewGrandTotal = () => {
    const discountCalc =
      invoice_discount_type === 0 || invoice_discount_type === "0"
        ? getFlatDiscount()
        : getPercentageDiscount();
    if (getSubTotal() === 0 || getNewSubTotal() === 0) {
      return 0;
    }
    return (
      parseFloat(getNewSubTotal()) - discountCalc - parseFloat(getSubTotal())
    );
  };
  const getDiscountedNewSubTotal = () => {
    const discountCalc =
      invoice_discount_type === 0 || invoice_discount_type === "0"
        ? getFlatDiscount()
        : getPercentageDiscount();
    return parseFloat(getNewSubTotal()) - discountCalc;
  };

  const getDueAmount = () => {
    const due = getGrandTotal() - paid;
    return due < 0 ? 0 : due;
  };
  const getNewDueAmount = () => {
    // const due = parseFloat(getNewSubTotal()) - previouslyPaid;
    const due = parseFloat(getNewGrandTotal());
    return due < 0 ? 0 : due;
  };

  const changeAmount = Math.abs(
    isNaN(getGrandTotal())
      ? 0
      : getGrandTotal() - InvoiceAllData["recived_amount"]
  );
  const handlePrint = useReactToPrint({
    content: () => print.current,
    documentTitle: "emp-data",
    bodyClass: "dis",
  });
  const handlePrint2 = () => {
    if (
      InvoiceAllData["invoice_from_email"] !== "" ||
      InvoiceAllData["invoice_to_email"] ||
      InvoiceAllData["invoice_bill_to"] ||
      InvoiceAllData["invoice_bill_form"]
    ) {
      handlePrint();
    } else {
      swal("Empty Data,Please fill the form");
    }
  };
  const handleDiscountChange = (index, discount) => {
    // Update the quantity in the state based on the index
    if (discount[0] === "-") {
      return showToast("error", "Negative value is not accepted");
    }
    const updatedProducts = [...selectedOldProducts];
    const currentPriceState = parseFloat(updatedProducts[index].oldPrice);
    console.log(currentPriceState, discount, "disc");
    const discountCalc =
      invoice_discount_type === 0 || invoice_discount_type === "0"
        ? discount
        : (currentPriceState / 100) * discount;
    updatedProducts[index].discount = discountCalc;
    setSelectedOldProducts(updatedProducts);
    finalOldTotal(
      index,
      // updatedProducts[index].price,
      updatedProducts[index].quantity,
      discountCalc
    );
  };
  const handleOldQuantity = (index, currentQty, maxQuantity) => {
    const updatedProducts = [...selectedOldProducts];
    if (currentQty[0] === "-") {
      return showToast("error", "Negative value is not accepted");
    }
    if (currentQty > maxQuantity) {
      showToast("error", "Exchange quantity can't exceed purchased quantity");
    } else {
      updatedProducts[index].quantity = currentQty;
      setSelectedOldProducts(updatedProducts);
      finalOldTotal(index, currentQty, updatedProducts[index].discount);
    }
  };
  const finalOldTotal = (index, quantity, discount) => {
    const updatedProducts = [...selectedOldProducts];
    const result =
      (parseInt(updatedProducts[index].oldPrice) - parseInt(discount)) *
      parseInt(quantity);
    updatedProducts[index].price = result;
  };
  const handleNewQuantity = (e, curr, innerCur) => {
    // HandleNewProductsInputValue(e, innerCur);
    if (e.target.value[0] === "-") {
      return showToast("error", "Negative value is not accepted");
    }
    const productName = curr;
    if (e.target.value < 0) {
      setPs(0);
    } else {
      setPs(e.target.value);
    }

    setNewMainState((prevInputs) => {
      const copiedObjects = Object.assign({}, newMainState); // Shallow copy
      // console.log("copiedObjects", copiedObjects);
      // copiedObjects["Blankets"]["variant"][index]['quantity'] = 12; // Assign new value
      const keyOfVariant = Object.keys(
        copiedObjects[productName]["variant"]
      ).filter(
        (curr) =>
          copiedObjects[productName]["variant"][curr]["name"] === innerCur.label
      );
      const variantName = innerCur.label;
      // console.log("innercur", innerCur);
      if (parseInt(e.target.value) < 0) {
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] = 0;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] = 0;
      } else if (parseInt(e.target.value) > innerCur.stock) {
        showToast("error", `Max Stock : ${innerCur.stock}`);
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] = 0;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] = 0;
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] =
          innerCur.stock;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] =
          innerCur.stock;
      } else {
        copiedObjects[productName]["variant"][keyOfVariant]["quantity"] =
          e.target.value;
        copiedObjects[productName]["variantObj"][variantName]["quantity"] =
          e.target.value;
      }

      let checkDiscountPrice =
        parseInt(copiedObjects[productName]["discount"]) === 0
          ? parseInt(copiedObjects[productName]["price"])
          : parseInt(copiedObjects[productName]["discount"]);
      let baseAndVariant;
      let baseAndVariantObj;
      if (invoice_discount_type === 0 || invoice_discount_type === "0") {
        baseAndVariant =
          parseInt(
            copiedObjects[productName]["variant"][keyOfVariant]["price"]
          ) +
          checkDiscountPrice -
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["in_discount"]
          );
        baseAndVariantObj =
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["price"]
          ) +
          checkDiscountPrice -
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["in_discount"]
          );
      } else {
        const variantPrice =
          parseInt(
            copiedObjects[productName]["variant"][keyOfVariant]["price"]
          ) + checkDiscountPrice;
        const variantPriceObj =
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["price"]
          ) + checkDiscountPrice;
        baseAndVariant =
          variantPriceObj -
          (variantPriceObj / 100) *
            parseInt(
              copiedObjects[productName]["variantObj"][variantName][
                "in_discount"
              ]
            );
        baseAndVariantObj =
          variantPriceObj -
          (variantPriceObj / 100) *
            parseInt(
              copiedObjects[productName]["variantObj"][variantName][
                "in_discount"
              ]
            );
      }
      // console.log("baseAndVariantObj price--", baseAndVariantObj);
      copiedObjects[productName]["variant"][keyOfVariant]["total"] =
        parseInt(
          copiedObjects[productName]["variant"][keyOfVariant]["quantity"]
        ) * baseAndVariant;

      copiedObjects[productName]["variantObj"][variantName]["total"] =
        parseInt(
          copiedObjects[productName]["variantObj"][variantName]["quantity"]
        ) * baseAndVariantObj;

      return copiedObjects; // Return copied object
    });
  };
  const handleNewDiscountChange = (e, curr, innerCur) => {
    // HandleNewProductsInputValue(e, innerCur);
    if (e.target.value[0] === "-") {
      return showToast("error", "Negative value is not accepted");
    }
    const productName = curr;

    setNewMainState((prevInputs) => {
      const copiedObjects = Object.assign({}, newMainState);
      const keyOfVariant = Object.keys(
        copiedObjects[productName]["variant"]
      ).filter(
        (curr) =>
          copiedObjects[productName]["variant"][curr]["name"] === innerCur.label
      );
      const variantName = innerCur.label;

      copiedObjects[productName]["variant"][keyOfVariant]["in_discount"] =
        e.target.value;
      copiedObjects[productName]["variantObj"][variantName]["in_discount"] =
        e.target.value;
      // }

      let checkDiscountPrice =
        parseInt(copiedObjects[productName]["discount"]) === 0
          ? parseInt(copiedObjects[productName]["price"])
          : parseInt(copiedObjects[productName]["discount"]);
      // console.log("checkDiscountPrice", checkDiscountPrice);
      let baseAndVariant;
      let baseAndVariantObj;
      if (invoice_discount_type === 0 || invoice_discount_type === "0") {
        baseAndVariant =
          parseInt(
            copiedObjects[productName]["variant"][keyOfVariant]["price"]
          ) +
          checkDiscountPrice -
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["in_discount"]
          );
        baseAndVariantObj =
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["price"]
          ) +
          checkDiscountPrice -
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["in_discount"]
          );
      } else {
        const variantPrice =
          parseInt(
            copiedObjects[productName]["variant"][keyOfVariant]["price"]
          ) + checkDiscountPrice;
        const variantPriceObj =
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["price"]
          ) + checkDiscountPrice;
        baseAndVariant = variantPrice - (variantPrice / 100) * e.target.value;
        baseAndVariantObj =
          variantPriceObj - (variantPriceObj / 100) * e.target.value;
      }
      if (
        parseInt(
          copiedObjects[productName]["variant"][keyOfVariant]["quantity"]
        ) > 0
      ) {
        copiedObjects[productName]["variant"][keyOfVariant]["total"] =
          parseInt(
            copiedObjects[productName]["variant"][keyOfVariant]["quantity"]
          ) * baseAndVariant;
      } else {
        copiedObjects[productName]["variant"][keyOfVariant]["total"] = 0;
      }
      if (
        parseInt(
          copiedObjects[productName]["variantObj"][variantName]["quantity"]
        ) > 0
      ) {
        copiedObjects[productName]["variantObj"][variantName]["total"] =
          parseInt(
            copiedObjects[productName]["variantObj"][variantName]["quantity"]
          ) * baseAndVariantObj;
      } else {
        copiedObjects[productName]["variantObj"][variantName]["total"] = 0;
      }

      return copiedObjects; // Return copied object
    });
  };
  if (!has_permissions(can_add_invoice)) {
    return <Permission />;
  }
  if (isLoading) {
    return <Loader />;
  }
  console.log(
    defaultValue,
    productToExchange,
    selectedOldProducts,
    "default value"
  );
  return (
    <>
      <div className="categorey-parent">
        <div
          className="invoice-b my-4 bg-white cat-child"
          style={{ borderTop: "0.5px solid #E0E0E0" }}
        >
          <h4 className=" my-2 mb-4 padding-main-head">Product Details</h4>
          <div className="border-p"></div>
          <div className="padding-main">
            <h5>Old Product</h5>
            <input
              className="minWidthForInvoiceCreateTable mb-5"
              type="text"
              placeholder="Invoice Number"
              value={invoice}
              onChange={(e) => setInvoice(e.target.value)}
            />
            <div className="main-select">
              <Select
                // styles={styles}
                isMulti
                placeholder="---Select---"
                isClearable
                // defaultValue={selectedOption}
                onChange={(selectedProduct) =>
                  setSelectedOldProducts(selectedProduct)
                }
                // onInputChange={inputProduct}
                options={invoiceProducts}
                value={selectedOldProducts}
              />
            </div>
            <div className="mt-3">
              <div
                className="rounded"
                style={{
                  background: "#FAFAFA",
                  display: "block",
                  overflowX: "hidden",
                  width: "100%",
                }}
              >
                <table className="item-tables table_my2">
                  <tbody>
                    {/* //Here-------------------------- */}
                    {Object.keys(mainState).map((curr, index) => {
                      return (
                        <>
                          {mainState[curr]["variant"]?.length > 0 ? (
                            <>
                              <div
                                className="mt-4  px-2"
                                style={{
                                  background: " rgba(245, 245, 245, 0.36)",
                                  border: "none",
                                  height: "119px",
                                }}
                              >
                                <div
                                  className="row py-3 my-3"
                                  style={{ height: "45px" }}
                                >
                                  <div
                                    className="col-6 minWidthForInvoiceCreateTable ps-4"
                                    style={{ textAlign: "start" }}
                                  >
                                    <p>Product</p>
                                  </div>
                                  {/* <div
                                    className="col-4 minWidthForInvoiceCreateTable  py-1"
                                    style={{ textAlign: "center" }}
                                  >
                                    Total Stock
                                  </div> */}
                                  <div
                                    className="col-5 minWidthForInvoiceCreateTable  py-1"
                                    style={{ textAlign: "center" }}
                                  >
                                    Variants
                                  </div>
                                </div>
                                <div
                                  className="row  "
                                  style={{ height: "45px" }}
                                >
                                  <div className="col-6 ps-4 py-1 d-flex align-items-center">
                                    <p className="text-capitalize">{curr}</p>
                                    <img
                                      width={50}
                                      style={{
                                        margin: "6px 0px !important",
                                        border: "0.2px solid #bcb0b0",
                                      }}
                                      height={50}
                                      src={
                                        !isLoading && mainState[curr]["thumb"]
                                      }
                                      alt="failed"
                                      className="ms-3 shadow-sm"
                                    />
                                    {/* {console.log(" mainState[curr][thumb", mainState[curr]["thumb"])} */}
                                  </div>
                                  {/* <div className="col-4 py-1">
                                    <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                                      {Number(mainState[curr]["stock"])} */}
                                  {/* {Number(mainState[curr]["stock"]) - Number(pS)} */}
                                  {/* </p>
                                  </div> */}
                                  <div className="col-5 text-center   py-1 pe-2">
                                    <div
                                      className=""
                                      style={{ marginTop: "-10px" }}
                                    >
                                      {/* <div className="" style={{ marginTop: "-10px" }}> */}
                                      <Select
                                        styles={{
                                          height: "45px",
                                          background: "black",
                                        }}
                                        isMulti
                                        placeholder="-Select Variants-"
                                        isClearable
                                        onChange={(value) => {
                                          handleInnerVariant(curr, value);
                                        }}
                                        options={
                                          mainState[curr]["variant"] !==
                                            undefined &&
                                          Object.values(
                                            mainState[curr]["variant"]
                                          ).map((curr) => ({
                                            label: curr.name,
                                            value: curr.id,
                                            price: curr.price,
                                            stock: curr.stock,
                                            quantity: 1,
                                            discount: curr.discount,
                                          }))
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div>No variant</div>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                  {/* this table is used instead */}
                  <thead style={{ border: "1.5px solid #E0E0E0" }}>
                    {selectedOldProducts.length !== 0 && (
                      <tr className="px-3" style={{ height: "45px" }}>
                        <th
                          className="minWidthForInvoiceCreateTable ps-4"
                          style={{ width: "349px", textAlign: "start" }}
                        >
                          Product
                        </th>
                        <th
                          className="minWidthForInvoiceCreateTable ps-4"
                          style={{ width: "349px", textAlign: "start" }}
                        >
                          Variant
                        </th>
                        {/* <th
                          className="minWidthForInvoiceCreateTable  py-3"
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          Price
                        </th> */}

                        {/* <th
                className="minWidthForInvoiceCreateTable  py-3"
                style={{ width: "150px", textAlign: "center" }}
              >
                In Stock
              </th> */}
                        <th
                          className="minWidthForInvoiceCreateTable  py-3"
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          Discount{" "}
                          {invoice_discount_type === "0" ||
                          invoice_discount_type === 0
                            ? "(Tk)"
                            : "(%)"}{" "}
                        </th>
                        <th
                          className="minWidthForInvoiceCreateTable py-3"
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          Purchase Quantity
                        </th>
                        <th
                          className="minWidthForInvoiceCreateTable py-3"
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          Exchange Quantity
                        </th>

                        <th
                          className="minWidthForInvoiceCreateTable py-3"
                          style={{ width: "150px", textAlign: "center" }}
                        >
                          Total Price
                        </th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {selectedOldProducts &&
                      selectedOldProducts.map((product, index) => (
                        <tr
                          key={index}
                          className="border-invoice"
                          style={{
                            height: "45px",
                          }}
                        >
                          <td className="ps-4 py-3">
                            <p className="text-capitalize">{product.label}</p>
                          </td>
                          <td className="ps-4 py-3">
                            <p className="text-capitalize">{product.variant}</p>
                          </td>
                          <td className="ps-4 py-3 text-center">
                            <input
                              className="invoiceCreateSmallInputs"
                              type="number"
                              min={0}
                              // value={product.discount}
                              onChange={(e) =>
                                handleDiscountChange(index, e.target.value)
                              }
                            />{" "}
                          </td>
                          <td className="ps-4 py-3">
                            <p className="text-capitalize text-center">
                              {product.maxQuantity}
                            </p>
                          </td>
                          <td className="ps-4 py-3">
                            {/* <p className="text-capitalize text-center">
                              {product.quantity}
                            </p> */}
                            <input
                              className="invoiceCreateSmallInputs"
                              type="number"
                              min={1}
                              value={product.quantity}
                              onChange={(e) =>
                                handleOldQuantity(
                                  index,
                                  e.target.value,
                                  product.maxQuantity
                                )
                              }
                            />
                          </td>
                          <td className="ps-4 py-3">
                            <p className="text-capitalize">
                              {parseInt(product.price)}
                            </p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="mt-2">
                  <h5>New Product</h5>
                  <table className="item-tables table_my2">
                    <tbody>
                      <div className="main-select">
                        <Select
                          // styles={styles}
                          isMulti
                          placeholder="---Select---"
                          isClearable
                          // defaultValue={selectedOption}
                          onChange={handleNewMultiInputChange}
                          onInputChange={newInputProduct}
                          options={newProductsList}
                          value={productToExchange}
                        />
                      </div>
                      {Object.keys(newMainState).map((curr, index) => {
                        return (
                          <>
                            {newMainState[curr]["variant"]?.length > 0 ? (
                              <>
                                <div
                                  className="mt-4  px-2"
                                  style={{
                                    background: " rgba(245, 245, 245, 0.36)",
                                    border: "none",
                                    height: "119px",
                                  }}
                                >
                                  <div
                                    className="row py-3 my-3"
                                    style={{ height: "45px" }}
                                  >
                                    <div
                                      className="col-2 minWidthForInvoiceCreateTable ps-4"
                                      style={{ textAlign: "start" }}
                                    >
                                      <p>Product</p>
                                    </div>
                                    <div
                                      className="col-4 minWidthForInvoiceCreateTable  py-1"
                                      style={{ textAlign: "center" }}
                                    >
                                      Total Stock
                                    </div>
                                    <div
                                      className="col-5 minWidthForInvoiceCreateTable  py-1"
                                      style={{ textAlign: "center" }}
                                    >
                                      Variants
                                    </div>
                                  </div>
                                  <div
                                    className="row  "
                                    style={{ height: "45px" }}
                                  >
                                    <div className="col-2 ps-4 py-1 d-flex align-items-center">
                                      <p className="text-capitalize">{curr}</p>
                                      <img
                                        width={50}
                                        style={{
                                          margin: "6px 0px !important",
                                          border: "0.2px solid #bcb0b0",
                                        }}
                                        height={50}
                                        src={
                                          !isLoading &&
                                          newMainState[curr]["thumb"]
                                        }
                                        alt="failed"
                                        className="ms-3 shadow-sm"
                                      />
                                      {/* {console.log(" mainState[curr][thumb", mainState[curr]["thumb"])} */}
                                    </div>
                                    <div className="col-4 py-1">
                                      <p className="text-capitalize text-center d-flex align-items-center justify-content-center">
                                        {Number(newMainState[curr]["stock"])}
                                        {/* {Number(mainState[curr]["stock"]) - Number(pS)} */}
                                      </p>
                                    </div>
                                    <div className="col-5 text-center   py-1 pe-2">
                                      <div
                                        className=""
                                        style={{ marginTop: "-10px" }}
                                      >
                                        {/* <div className="" style={{ marginTop: "-10px" }}> */}
                                        <Select
                                          styles={{
                                            height: "45px",
                                            background: "black",
                                          }}
                                          isMulti
                                          placeholder="-Select Variants-"
                                          isClearable
                                          onChange={(value) => {
                                            handleNewInnerVariant(curr, value);
                                          }}
                                          options={
                                            newMainState[curr]["variant"] !==
                                              undefined &&
                                            Object.values(
                                              newMainState[curr]["variant"]
                                            ).map((curr) => ({
                                              label: curr.name,
                                              value: curr.id,
                                              price: curr.price,
                                              stock: curr.stock,
                                              quantity: 1,
                                            }))
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <table className="mapTable shadow-sm ms-2">
                                  <thead
                                    className="mt-4"
                                    style={{
                                      background: " rgba(245, 245, 245, 0.36)",
                                    }}
                                  ></thead>
                                  <tbody className="innerTbody w-100">
                                    {newMainInnerState !== undefined &&
                                      newMainInnerState[curr] !== undefined &&
                                      newMainInnerState[curr].map(
                                        (innerCur, index) => {
                                          return (
                                            <>
                                              {index === 0 && (
                                                <NewVariantThead />
                                              )}

                                              <tbody>
                                                <tr
                                                  className="border-invoice"
                                                  style={{
                                                    height: "45px",
                                                  }}
                                                >
                                                  <td className="ps-4 py-3">
                                                    <p className="text-capitalize">
                                                      {innerCur.label}
                                                    </p>
                                                    {/* <input className="invoiceCreateBigInputs" value={name} type="text" /> */}
                                                  </td>
                                                  <td
                                                    className="text-center   py-3"
                                                    style={{
                                                      minWidth: "300px",
                                                    }}
                                                  >
                                                    <input
                                                      name="in_price"
                                                      // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                      value={getNewBasePlusVariant(
                                                        curr,
                                                        innerCur
                                                      )}
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="text"
                                                      //
                                                    />
                                                  </td>
                                                  <td
                                                    className="text-center   py-3"
                                                    style={{
                                                      minWidth: "300px",
                                                    }}
                                                  >
                                                    <input
                                                      name="in_discount"
                                                      // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                      value={
                                                        (newMainState ===
                                                          undefined &&
                                                          newMainState[curr] ===
                                                            undefined &&
                                                          newMainState[curr][
                                                            "variantObj"
                                                          ] === undefined &&
                                                          newMainState[curr][
                                                            "variantObj"
                                                          ][innerCur.label] ===
                                                            undefined &&
                                                          newMainState[curr][
                                                            "variantObj"
                                                          ][innerCur.label][
                                                            "in_discount"
                                                          ] === undefined) ||
                                                        newMainState[curr][
                                                          "variantObj"
                                                        ][innerCur.label][
                                                          "in_discount"
                                                        ] === "-1" ||
                                                        newMainState[curr][
                                                          "variantObj"
                                                        ][innerCur.label][
                                                          "in_discount"
                                                        ] === -1
                                                          ? 0
                                                          : newMainState[curr][
                                                              "variantObj"
                                                            ][innerCur.label][
                                                              "in_discount"
                                                            ]
                                                      }
                                                      onChange={(e) =>
                                                        handleNewDiscountChange(
                                                          e,
                                                          curr,
                                                          innerCur
                                                        )
                                                      }
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="text"
                                                      //
                                                    />
                                                  </td>
                                                  <td className="text-center   py-3">
                                                    <input
                                                      name="in_price"
                                                      // onChange={(e) => HandleNewProductsInputValue(e, curr)}
                                                      value={Number(
                                                        innerCur.stock
                                                      )}
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="text"
                                                    />
                                                    {/* {console.log(mainState[curr]["variantObj"][innerCur],'------vvv----------')} */}
                                                  </td>
                                                  <td className="text-center   py-3">
                                                    <input
                                                      name="in_quantity"
                                                      onWheel={(e) =>
                                                        e.target.blur()
                                                      }
                                                      onChange={(e) =>
                                                        handleNewQuantity(
                                                          e,
                                                          curr,
                                                          innerCur
                                                        )
                                                      }
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="number"
                                                      value={
                                                        (newMainState ===
                                                          undefined &&
                                                          newMainState[curr] ===
                                                            undefined &&
                                                          newMainState[curr][
                                                            "variantObj"
                                                          ] === undefined &&
                                                          newMainState[curr][
                                                            "variantObj"
                                                          ][innerCur.label] ===
                                                            undefined &&
                                                          newMainState[curr][
                                                            "variantObj"
                                                          ][innerCur.label][
                                                            "quantity"
                                                          ] === undefined) ||
                                                        newMainState[curr][
                                                          "variantObj"
                                                        ][innerCur.label][
                                                          "quantity"
                                                        ] === "-1" ||
                                                        newMainState[curr][
                                                          "variantObj"
                                                        ][innerCur.label][
                                                          "quantity"
                                                        ] === -1
                                                          ? 0
                                                          : newMainState[curr][
                                                              "variantObj"
                                                            ][innerCur.label][
                                                              "quantity"
                                                            ]
                                                      }
                                                    />
                                                  </td>
                                                  <td className="text-center py-3 ">
                                                    <input
                                                      // name="in_total"
                                                      value={
                                                        (newMainState ===
                                                          undefined &&
                                                          newMainState[curr] ===
                                                            undefined) ||
                                                        isNaN(
                                                          newMainState[curr][
                                                            "variantObj"
                                                          ][innerCur.label][
                                                            "total"
                                                          ]
                                                        )
                                                          ? 0
                                                          : parseInt(
                                                              newMainState[
                                                                curr
                                                              ]["variantObj"][
                                                                innerCur.label
                                                              ]["total"]
                                                            )
                                                      }
                                                      className="invoiceCreateSmallInputs"
                                                      placeholder="00.00"
                                                      type="text"
                                                    />
                                                  </td>{" "}
                                                </tr>
                                              </tbody>
                                            </>
                                          );
                                        }
                                      )}
                                  </tbody>

                                  {/* <VariantTable /> */}
                                </table>
                              </>
                            ) : (
                              <div>No variant</div>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="d-flex flex-column flex-md-row mt-4 justify-content-between mx-4">
                  <div>
                    <h6>Total Item : {Object?.keys(newMainState)?.length}</h6>
                  </div>
                  {/* sub_total, delivery_charge, discount, total, paid_amount, Due_amount */}
                  <div>
                    <div
                      className="d-flex justify-content-between "
                      style={{
                        color: "#212121",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      <p>New Sub Total :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss"
                          type="text"
                          value={isNaN(getNewSubTotal()) ? 0 : getNewSubTotal()}
                        />
                      </p>
                    </div>
                    <div
                      className="d-flex justify-content-between "
                      style={{
                        color: "#212121",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      <p>Previous Sub Total :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss"
                          type="text"
                          value={isNaN(getSubTotal()) ? 0 : getSubTotal()}
                        />
                      </p>
                    </div>

                    <div className="d-flex justify-content-between cl d-none">
                      <p>Delivery Charge : </p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss"
                          type="number"
                          onChange={(e) => setCustomCharge(e.target.value)}
                          // value={invoice_payment_type === 0 || invoice_payment_type === "0" ? delivery_charge[invoice_delivery_charge] : 0}
                          value={getDeliveryCharge()}
                          style={{ border: "1px solid gray" }}
                        />
                      </p>
                    </div>
                    <div className="d-flex justify-content-between pl pt-2">
                      <p>
                        Discount{" "}
                        {invoice_discount_type === "0" ||
                        invoice_discount_type === 0
                          ? ""
                          : "%"}{" "}
                        :
                      </p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss paid_input_css"
                          name="invoice_paid"
                          type="text"
                          value={Discount}
                          onChange={(e) => {
                            if (e.target.value[0] === "-") {
                              return showToast(
                                "error",
                                "Negative value is not accepted"
                              );
                            }
                            setDiscount(e.target.value);
                          }}
                        />
                      </p>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        color: "#212121",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      <p>
                        Price Difference
                        {getNewGrandTotal() < 0
                          ? "(Non-refundable):"
                          : "(Have To Pay):"}
                      </p>
                      <p className="ms-3">
                        <input
                          className="ms-3 font-weight-bold invoiceInpCss"
                          style={{ fontWeight: "700" }}
                          type="text"
                          value={
                            isNaN(getNewGrandTotal())
                              ? 0
                              : Math.abs(getNewGrandTotal())
                          }
                        />
                      </p>
                    </div>

                    <div className="d-flex justify-content-between pt-2">
                      <p>Previous Paid amount :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss paid_input_css"
                          name="invoice_paid"
                          type="text"
                          value={getSubTotal()}
                          // onChange={(e) => setPreviouslyPaid(e.target.value)}
                        />
                      </p>
                    </div>

                    <div className="d-flex justify-content-between cl">
                      <p>Due amount :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss"
                          type="text"
                          name="invoice_due"
                          value={
                            isNaN(getNewDueAmount()) ? 0 : Math.abs(getNewDueAmount() - paid)
                          }
                        />
                      </p>{" "}
                    </div>
                    <div className="d-flex justify-content-between cl">
                      <p>Recieved amount :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss paid_input_css"
                          type="number"
                          name="recived_amount"
                          min={0}
                          value={InvoiceAllData["recived_amount"]}
                          onChange={(e) => {
                            if (e.target.value[0] === "-") {
                              return showToast(
                                "error",
                                "Negative value is not accepted"
                              );
                            }
                            handleInputs(e, SetInvoiceAllData);
                            setpaid(
                              InvoiceAllData["recived_amount"] >=
                                getNewDueAmount()
                                ? getNewDueAmount()
                                : 0
                            );
                          }}
                        />
                      </p>{" "}
                    </div>
                    <div className="d-flex justify-content-between cl">
                      <p>Change Amount :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss"
                          type="number"
                          name="invoice_examount"
                          value={Math.abs(
                            isNaN(getNewDueAmount()) ||
                              InvoiceAllData["recived_amount"] == 0 ||
                              InvoiceAllData["recived_amount"] <
                                getNewDueAmount()
                              ? 0
                              : getNewDueAmount() -
                                  InvoiceAllData["recived_amount"]
                          )}
                        />
                      </p>{" "}
                    </div>
                    <div className="d-flex justify-content-between pl pt-2">
                      <p>New Paid amount :</p>
                      <p className="ms-3 ">
                        <input
                          className="ms-3 invoiceInpCss paid_input_css"
                          name="invoice_paid"
                          type="text"
                          value={paid}
                          onChange={(e) => {
                            if (e.target.value[0] === "-") {
                              return showToast(
                                "error",
                                "Negative value is not accepted"
                              );
                            }
                            setpaid(e.target.value);
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white cat-child ">
          <div
            className="d-flex align-items-center"
            style={{ padding: "20px 24px ", border: "0.5px solid #E0E0E0" }}
          >
            <h3
              style={{ fontSize: "23px", marginTop: "6px", marginLeft: "12px" }}
            >
              Invoice Details
            </h3>
          </div>
          {/* -----------product form---------- */}
          <div className="product-main createInvoiceInputContainer">
            <div className="row">
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="Invoice Number" /> */}
                <p>Invoice Number</p>

                <InputGroup className="mb-3">
                  <Form.Control
                    disabled
                    aria-label="Username"
                    placeholder="This will be generated automatically."
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-md-6">
                <p>Barcode</p>
                <InputGroup className="mb-3">
                  <Form.Control
                    disabled
                    placeholder="This will be generated automatically"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>

              <div className="col-12 col-md-6">
                <RequiredLabel text="Invoice Date" />
                <div className="position-relative">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="date"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      placeholder="Select a date"
                      name="invoice_date"
                      value={InvoiceAllData["invoice_date"]}
                      onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    />
                  </InputGroup>
                  {/* <div className="calenderContainer">
                    <CalendarBlank size={20} />
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <RequiredLabel text={"Delivery Type"} />
                <div className="" style={{ height: "47px" }}>
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) => setinvoice_delivery_type(e.target.value)}
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                  >
                    <option value={0}>Regular</option>
                    <option value={1}>Urgent</option>
                  </Form.Select>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <RequiredLabel text="From Mobile" />
                {/* <p></p> */}

                <div className="d-flex phoneContainer ">
                  <PhoneInput
                    inputClass="phoneinput"
                    disableDropdown
                    onChange={(e) => setFromCode(e)}
                    country={"bd"}
                  />
                  {/* <PhoneInput inputClass="phoneinput" disableDropdown onChange={(e) => setFromCode(e)} enableSearch={true} country={"bd"} /> */}
                  <div className="w-100">
                    <Form.Control
                      aria-label="Input number"
                      placeholder="Input number"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      className="phone_insert_input"
                      name="invoice_from_mobile"
                      value={InvoiceAllData["invoice_from_mobile"]}
                      onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 mb-3">
                <RequiredLabel text="To Mobile" />

                <div className="d-flex phoneContainer ">
                  <PhoneInput
                    inputClass="phoneinput"
                    disableDropdown
                    onChange={(e) => setToCode(e)}
                    enableSearch={true}
                    country={"bd"}
                  />
                  {/* <PhoneInput inputClass="phoneinput"  onChange={(e) => setToCode(e)} enableSearch={true} country={"bd"} /> */}
                  <div className="w-100">
                    <Form.Control
                      aria-label="Input number"
                      placeholder="Input number"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      className="phone_insert_input"
                      name="invoice_to_mobile"
                      value={InvoiceAllData["invoice_to_mobile"]}
                      // onKeyUp={(e) => toPhoneHandle(e)}
                      onChange={(e) => toPhoneHandle(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 mt-3">
                <RequiredLabel text="Bill From" />

                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    name="invoice_bill_from"
                    value={InvoiceAllData["invoice_bill_from"]}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </InputGroup>
              </div>

              <div className="col-12 col-md-6 mt-3">
                {/* <RequiredLabel text="Bill To" /> */}
                <p>Bill To</p>

                <InputGroup className="mb-3">
                  <Form.Control
                    name="invoice_bill_to"
                    value={
                      Found
                        ? FoundCustomer.name
                        : InvoiceAllData["invoice_bill_to"]
                    }
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>

              {/* new  */}
              <div className="col-12 col-md-6">
                <RequiredLabel text={"Discount Type"} />
                <div className="mb-3" style={{ height: "47px" }}>
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) => setinvoice_discount_type(e.target.value)}
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                    value={invoice_discount_type}
                  >
                    <option value={0}>Flat </option>
                    <option value={1}>Percentage</option>
                  </Form.Select>
                </div>
              </div>

              <div className="col-12 col-md-6 ">
                <RequiredLabel text={"Delivery Charge"} />
                <div className="mb-3">
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) => {
                      if (Number(e.target.value) === 0) {
                        setCustomCharge(80);
                      } else if (Number(e.target.value) === 1) {
                        setCustomCharge(150);
                      }
                      setinvoice_delivery_charge(e.target.value);
                    }}
                    disabled
                    value={invoice_delivery_charge}
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                  >
                    <option value={0}>Inside Dhaka : 80/-</option>
                    <option value={1}>Outside Dhaka : 150/-</option>
                    <option value={2}>Custom</option>
                  </Form.Select>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <RequiredLabel text="From Email" />

                <InputGroup className="mb-3">
                  <Form.Control
                    aria-label="Username"
                    type="email"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    name="invoice_from_email"
                    value={InvoiceAllData["invoice_from_email"]}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </InputGroup>
              </div>

              {Found ? (
                <div className="col-12 col-md-6 ">
                  {/* <RequiredLabel text="To Email" /> */}
                  <p>To Email</p>

                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      name="invoice_to_email"
                      type="email"
                      value={FoundCustomer.email}
                      onChange={(e) =>
                        setFoundCustomer({
                          ...FoundCustomer,
                          email: e.target.value,
                        })
                      }
                    />
                  </InputGroup>
                </div>
              ) : (
                <div className="col-12 col-md-6 ">
                  {/* <RequiredLabel text="To Email" /> */}
                  <p>To Email</p>

                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{ backgroundColor: "#FAFAFA" }}
                      name="invoice_to_email"
                      type="email"
                      value={InvoiceAllData["invoice_to_email"]}
                      onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                    />
                  </InputGroup>
                </div>
              )}

              <div className="col-12 col-md-6">
                <RequiredLabel text="From Address" />

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                    name="invoice_from_address"
                    value={InvoiceAllData["invoice_from_address"]}
                    onChange={(e) => handleInputs(e, SetInvoiceAllData)}
                  />
                </InputGroup>
              </div>

              <div className="col-12 col-md-6"></div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="To Address 1" /> */}
                <p>To Address 1</p>

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="invoice_to_address"
                    value={
                      Found
                        ? FoundCustomer.to_address
                        : InvoiceAllData["invoice_to_address"]
                    }
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                            ...FoundCustomer,
                            to_address: e.target.value,
                          })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>
              <div className="col-12 col-md-6">
                <p>To Address 2</p>

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="invoice_to_address2"
                    value={
                      Found
                        ? FoundCustomer.to_address2
                        : InvoiceAllData["invoice_to_address2"]
                    }
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                            ...FoundCustomer,
                            to_address: e.target.value,
                          })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>

              {/* country */}
              <div className="col-12 col-md-6">
                <RequiredLabel text="To Country" />

                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="to_country"
                    value={
                      Found
                        ? FoundCustomer.to_country
                        : InvoiceAllData["to_country"]
                    }
                    onChange={(e) =>
                      Found
                        ? setFoundCustomer({
                            ...FoundCustomer,
                            to_country: e.target.value,
                          })
                        : handleInputs(e, SetInvoiceAllData)
                    }
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>
              {/* divi */}
              {/* new address inputs start */}
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="To Division" /> */}
                <p>To Division</p>
                <Controller
                  name="division"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      {...field}
                      value={selectedDivision}
                      onChange={handleDivisionChange}
                      styles={customStyles}
                      options={divisions.map(({ name, id }) => {
                        return { label: name, value: id };
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="To District" /> */}
                <p>To District</p>
                <Controller
                  name="district"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      {...field}
                      value={selectedDistrict}
                      onChange={handleDistrictChange}
                      styles={customStyles}
                      // defaultValue={ label: "", value: "" }
                      options={districtList?.map(({ name, id }) => {
                        return { label: name, value: id };
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="" /> */}
                <p>To City</p>
                <Controller
                  name="town"
                  control={control}
                  // rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      isSearchable
                      {...field}
                      value={selectedTown}
                      onChange={handleTownChange}
                      styles={customStyles}
                      // defaultValue={ label: "", value: "" }
                      options={upazillaList?.map(({ name, id }) => {
                        return { label: name, value: id };
                      })}
                    />
                  )}
                />
              </div>
              <div className="col-12 col-md-6 mt-2">
                {/* <RequiredLabel text="To Division" /> */}
                <p>Post Code</p>

                <input
                  style={{ width: "100%" }}
                  type="text"
                  {...register("zip")}
                />
              </div>
              {/* new address input end */}
              <div className="col-12 col-md-6">
                <RequiredLabel text={"Payment Type"} />
                <div className="mb-3">
                  <Form.Select
                    size="lg"
                    placeholder="---Select---"
                    onChange={(e) => {
                      clearPaymentALlData();
                      setinvoice_payment_type(e.target.value);
                    }}
                    style={{
                      backgroundColor: "#FAFAFA",
                      fontSize: "16px",
                      height: "47px",
                      borderRadius: "8px",
                      color: "#828282",
                    }}
                  >
                    {paymentType.map((curr) => {
                      return <option value={curr.value}>{curr.label}</option>;
                    })}
                  </Form.Select>
                </div>
              </div>
              <div className="col-12 col-md-6">
                {/* <RequiredLabel text="To District" /> */}
                <p>Reference ID</p>
                <InputGroup className="mb-3 addressInput">
                  <Form.Control
                    aria-label="Username"
                    name="reference_id"
                    value={paymentAllData["reference_id"]}
                    onChange={(e) => handleInputs(e, setPaymentAllData)}
                    aria-describedby="basic-addon1"
                    style={{ backgroundColor: "#FAFAFA" }}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
        {invoice_payment_type === 8 ||
          (invoice_payment_type === "8" && (
            <div className="invoice-b my-4 ms-1 bg-white cat-child row">
              <div className="col-12 my-3 ">
                <p>Multiple Payment </p>
                <div className="position-relative">
                  <Select
                    // styles={styles}
                    isMulti
                    placeholder="---Select---"
                    isClearable
                    // defaultValue={selectedOption}
                    onChange={(newValue) => setMixPaymentData(newValue)}
                    options={paymentTypeForMix}
                    value={mixPaymentData}
                  />
                </div>
              </div>
              {mixPaymentData?.map((data) => (
                <>
                  <div className="col-12 col-md-6">
                    {/* <RequiredLabel text="To District" /> */}
                    <p>{data?.label}</p>
                    <InputGroup className="mb-3 addressInput">
                      <Form.Control
                        aria-label="Username"
                        type="number"
                        min={0}
                        name={data?.label}
                        defaultValue={data?.amount}
                        onChange={(e) =>
                          handleInputChange(data.value, e.target.value)
                        }
                        aria-describedby="basic-addon1"
                        style={{ backgroundColor: "#FAFAFA", height: "40px" }}
                      />
                    </InputGroup>
                  </div>
                </>
              ))}
            </div>
          ))}

        <div
          className={`invoice-b my-4 bg-white cat-child ${
            invoice_payment_type === 0 ||
            invoice_payment_type === "0" ||
            invoice_payment_type === 6 ||
            invoice_payment_type === "6" ||
            invoice_payment_type === "7" ||
            invoice_payment_type === "8" ||
            invoice_payment_type === 7 ||
            invoice_payment_type === 8
              ? "d-none"
              : ""
          }`}
          style={{ borderTop: "0.5px solid #E0E0E0" }}
        >
          <h4 className=" my-2 mb-4 padding-main-head">Payment Details</h4>
          <div className="border-p"></div>
          {/* ==========for bKash========== */}
          {invoice_payment_type === 3 ||
            (invoice_payment_type === "3" && (
              <div className="padding-main row">
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="bKash Number" /> */}
                  <p>Bkash Number</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="bkash_number"
                      value={paymentAllData["bkash_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="TRX Number" /> */}
                  <p>TNX Number</p>
                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="bkash_trx_number"
                      value={paymentAllData["bkash_trx_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
              </div>
            ))}
          {invoice_payment_type === 5 ||
            (invoice_payment_type === "5" && (
              <div className="padding-main row">
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="bKash Number" /> */}
                  <p>Nagad Number</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="nagad_number"
                      value={paymentAllData["nagad_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="TRX Number" /> */}
                  <p>TNX Number</p>
                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="nagad_trx_number"
                      value={paymentAllData["nagad_trx_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
              </div>
            ))}
          {/* ==========for bKash========== */}
          {/* ===========For Bank Transfer */}
          {invoice_payment_type === 2 ||
            (invoice_payment_type === "2" && (
              <div className="padding-main row">
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Bank Name" /> */}
                  <p>Bank Name</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="bank_name"
                      value={paymentAllData["bank_name"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Branch Name" /> */}
                  {/* <RequiredLabel text="Card Holder Name" /> */}
                  <p>Branch Name</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="banK_branch_name"
                      value={paymentAllData["banK_branch_name"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Acount Number" /> */}
                  <p>Account Number</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="banK_account_number"
                      value={paymentAllData["banK_account_number"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Account Name" /> */}
                  <p>Account Name</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="banK_account_name"
                      value={paymentAllData["banK_account_name"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
              </div>
            ))}

          {/* ===========For Bank Transfer=============== */}
          {/* ===========For Card Payment=============== */}
          {invoice_payment_type === 1 ||
            (invoice_payment_type === "1" && (
              <div className="padding-main row">
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Card Number" /> */}
                  <p>Card Number</p>

                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="card_number"
                      value={paymentAllData["card_number"]}
                      // placeholder="4111111111111111"
                      // placeholder="4123412341234123"
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
                <div className="col-12 col-md-6">
                  {/* <RequiredLabel text="Card Holder Name" /> */}
                  <p>Card Holder Name</p>
                  <InputGroup className="mb-3 addressInput">
                    <Form.Control
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        backgroundColor: "#FAFAFA",
                        fontSize: "16px",
                        height: "47px",
                        borderRadius: "8px",
                        color: "#828282",
                      }}
                      name="card_holder_name"
                      value={paymentAllData["card_holder_name"]}
                      onChange={(e) => handleInputs(e, setPaymentAllData)}
                    />
                  </InputGroup>
                </div>
              </div>
            ))}

          {/* ===========For Card Payment=============== */}
        </div>

        {/* ===============scaner============== */}
        <Scanner2 setScanCode={setScanCode} />
        {/* ===============scaner============== */}
        {/* ====================attribute=================== */}

        {/* ====================attribute=================== */}
        <div
          className="product-main my-4 bg-white cat-child"
          style={{ borderTop: "0.5px solid #E0E0E0" }}
        >
          <div>
            <p>Payment Status</p>
            <Select
              placeholder="---Select---"
              isClearable
              defaultValue={invoice_choices[0]}
              onChange={(actionMeta) => setinvoice_status(actionMeta.value)}
              options={invoice_choices}
            />
            <p className="mt-4">Delivery Status</p>

            <Select
              placeholder="---Select---"
              isClearable
              defaultValue={delivery_choices[0]}
              onChange={(actionMeta) => setDelivey_status(actionMeta.value)}
              options={delivery_choices}
            />

            {/* notes */}
            <div className="mt-3 d-flex align-content-center">
              <PencilSimple size={12} weight="light" className="mt-0 pe-1" />{" "}
              <p style={{ color: "#333333" }} className="mb-2">
                Notes
              </p>
            </div>
            <Form.Control
              as="textarea"
              placeholder=""
              className="mb-3"
              name="invoice_notes"
              value={InvoiceAllData["invoice_notes"]}
              onChange={(e) => handleInputs(e, SetInvoiceAllData)}
              style={{
                height: "100px",
                resize: "none",
                backgroundColor: "#FAFAFA",
              }}
            />
            {/* notes */}
            <div className="mt-3 d-flex align-content-center">
              <PencilSimple size={12} weight="light" className="mt-0 pe-1" />{" "}
              <p style={{ color: "#333333" }} className="mb-2">
                Outlet Notes
              </p>
            </div>
            <Form.Control
              as="textarea"
              placeholder=""
              className="mb-3"
              name="invoice_notes2"
              value={InvoiceAllData["invoice_notes2"]}
              onChange={(e) => handleInputs(e, SetInvoiceAllData)}
              style={{
                height: "100px",
                resize: "none",
                backgroundColor: "#FAFAFA",
              }}
            />
          </div>

          <div className="row d-flex justify-content-between"></div>
        </div>

        <div
          className="padding-btn-head mb-4 d-flex justify-content-end  bg-white cat-child"
          style={{ border: "0.5px solid #E0E0E0" }}
        >
          <div className="" style={{ marginLeft: "-10px" }}>
            <button
              className={`btn rounded border align-items-center me-2 mt-2 d-none }`}
              disabled={!InvoiceAllData}
              onClick={handlePrint2}
            >
              <DoIcon className="doc" /> Preview PDF
            </button>

            <button
              onClick={() => {
                ValidateData(false);
              }}
              className="btn rounded border me-2 mt-2"
              onDoubleClick={() => showToast("success", "Invoice Creating")}
            >
              Save Invoice
            </button>
            <button
              className="btn rounded border me-2 mt-2"
              onClick={() => history.push(path.invoice_list)}
            >
              Cancel
            </button>
            {InvoiceAllData["invoice_to_email"] !== "" &&
              !Found &&
              validateEmail(InvoiceAllData["invoice_to_email"]) && (
                <button
                  onClick={SaveAndSendEmail}
                  className="btn rounded border text-white mt-2"
                  style={{ background: "black" }}
                  onDoubleClick={() => showToast("success", "Invoice Creating")}
                >
                  Save & Send PDF
                </button>
              )}

            {Found &&
              FoundCustomer?.email !== "" &&
              validateEmail(FoundCustomer?.email) && (
                <button
                  onClick={SaveAndSendEmail}
                  className="btn rounded border text-white mt-2"
                  style={{ background: "black" }}
                  onDoubleClick={() => showToast("success", "Invoice Creating")}
                >
                  Save & Send PDF
                </button>
              )}
            <div className="d-none">
              <PreviewCreate
                print={print}
                status={invoice_payment_type}
                Found={Found}
                InvoiceAllData={InvoiceAllData}
                FoundCustomer={FoundCustomer}
                mainState={mainState}
                invStatus={invoice_status}
                Jdata={Jdata}
              ></PreviewCreate>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateExchange;