
// new api demo
/* The line `const BASE_URL = "https://kaaruj.backend.clients.devsstream.com/"` is declaring a constant
variable named `BASE_URL` and assigning it the value of the specified URL
"https://kaaruj.backend.clients.devsstream.com/". This variable is being exported, which means it
can be accessed in other modules that import it. It is commonly used to store the base URL for
making API requests in a web application. */
/* The line `export const BASE_URL = "https://kaaruj.backend.clients.devsstream.com/"` is declaring a
constant variable named `BASE_URL` and assigning it the value of the specified URL
"https://kaaruj.backend.clients.devsstream.com/". The `export` keyword is used to make the
`BASE_URL` variable accessible outside of the current module, allowing other modules to import and
use this variable. This is commonly used in JavaScript modules to share variables, functions, or
classes between different parts of a program. */
// export  const BASE_URL = "https://kaaruj.backend.clients.devsstream.com/"
// live
export  const BASE_URL = "https://website-backend.kaaruj.cloud/";
// demourl 
// export const BASE_URL = "https://ecom-outlet.clients.devsstream.com/";